
import { Component, Vue } from "vue-property-decorator";
import AuthPasswordInput from "@/components/auth/AuthPasswordInput.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: { AuthPasswordInput },
})
export default class ProfilePasswordForm extends Vue {
  protected loading = false;
  protected password: string | null = null;
  protected updateStatus: string | null = null;
  protected error: string | null = null;
  protected showAllFormErrors = false;

  async updatePassword() {
    if (this.$v.$invalid) {
      this.showAllFormErrors = true;
      return;
    }

    this.loading = true;
    const result = await this.$store.dispatch("auth/profileUpdatePassword", {
      password: this.password,
    });
    this.loading = false;
    this.updateStatus = result ? "success" : "error";
  }

  @Validations()
  validations = {
    password: { required },
  };
}
