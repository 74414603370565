
import { Component, Vue } from "vue-property-decorator";
import ProfileDataForm from "@/components/myaccount/profile/ProfileDataForm.vue";
import ProfilePasswordForm from "@/components/myaccount/profile/ProfilePasswordForm.vue";
import ProfilePersonalisation from "@/components/myaccount/ProfilePersonalisation.vue";

@Component({
  components: {
    ProfileDataForm,
    ProfilePasswordForm,
    ProfilePersonalisation,
  },
})
export default class MyAccountProfile extends Vue {}
