
import { Component, Vue, Prop } from "vue-property-decorator";
import { ITab } from "@/models/Global";
import { IUser } from "@/models/User";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class MyAccountHeader extends Vue {
  @Prop({ type: Array, default: null })
  protected accountTabs?: ITab[];

  protected user!: IUser;

  protected handleTabChanged(tabName: string) {
    const tab = this.accountTabs?.find((tab) => {
      return tab.name === tabName;
    });
    if (tab == null || this.$route.name === tab.name) return;
    this.$router.push({ name: tab.routeName });
  }

  protected get availableTabs(): number {
    return this.accountTabs?.filter((tab) => tab.available).length || 0;
  }
}
