
import { Component, Vue } from "vue-property-decorator";
import { ITab } from "@/models/Global";
import MyAccountHeader from "@/components/myaccount/MyAccountHeader.vue";
import MyAccountProfile from "@/components/myaccount/MyAccountProfile.vue";
import CompanyData from "@/components/myaccount/CompanyData.vue";

@Component({
  components: { MyAccountHeader, MyAccountProfile, CompanyData },
})
export default class Profile extends Vue {
  protected get routeName(): string {
    return this.$route.name ? this.$route.name : "";
  }
  protected get currentComponent(): string {
    const tab = this.accountTabs.find((tab) => {
      return tab.selected;
    });
    return tab?.component || this.accountTabs[0].component;
  }
  protected get accountTabs(): ITab[] {
    return [
      {
        name: "myAccount",
        label: "myAccount",
        component: "myAccountProfile",
        selected: this.routeName === "myAccount",
        routeName: "myAccount",
        available: true,
      },
      {
        name: "companyData",
        label: "companyData",
        component: "CompanyData",
        selected: this.routeName === "companyData",
        routeName: "companyData",
        available: true,
      },
    ];
  }
}
