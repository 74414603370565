
import { Component, Vue } from "vue-property-decorator";
import { IUser, IUserDataToUpdate } from "@/models/User";
import { IPhone } from "@/models/Global";
import { DEFAULT_PHONE } from "@/constants";
import { mapGetters } from "vuex";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { isValidPhone } from "@/services/FormService";

@Component({
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class ProfileDataForm extends Vue {
  protected user!: IUser;
  protected loading = false;
  protected userFirstName = "";
  protected userEmail = "";

  protected phone: IPhone = DEFAULT_PHONE;
  protected updateStatus: string | null = null;
  protected error: string | null = null;

  protected get userPhone(): IPhone | string {
    return this.user?.phone?.phone.toString() || "";
  }

  protected set userPhone(value: IPhone | string) {
    if (typeof value === "string") {
      this.phone.phone = +value;
    } else {
      if (value.national) this.phone.phone = value.national;
      this.phone.isValid = value.isValid;
      this.phone.national = value.national;
      this.phone.internationalPhone = value.internationalPhone;
    }
  }

  mounted() {
    this.userFirstName = this.user.firstName || "";
    this.userEmail = this.user.email || "";
  }

  async updateData() {
    const data: IUserDataToUpdate = {
      actingHousfyAccountId: this.user.housfyAccountId,
      firstName: this.userFirstName,
      lastName: this.user.lastName,
      phone: this.phone?.internationalPhone,
    };
    this.loading = true;
    const result = await this.$store.dispatch("auth/profileUpdateData", data);
    this.loading = false;
    this.updateStatus = result ? "success" : "error";
  }

  @Validations()
  validations = {
    userFirstName: { required },
    phone: { required, isValidPhone },
  };
}
