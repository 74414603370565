
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/models/User";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class CompanyData extends Vue {
  protected user!: IUser;

  protected file = "Contrato-colaboracion.pdf";
  protected fileName =
    this.user && this.user.contractFileName
      ? this.user.contractFileName
      : "Contrato-colaboracion";
  protected bankAccount =
    this.user && this.user.bankAccount ? this.user.bankAccount : "";

  protected get secretBankAccount() {
    const digits = 4;
    let masked = "";

    if (!this.bankAccount) return "";
    else {
      for (let i = 0; i < this.bankAccount.length; i = i + digits) {
        masked +=
          this.bankAccount.substring(i, i + digits).replace(/[a-z\d]/gi, "●") +
          " ";
      }
      return (
        masked +
        this.bankAccount.substring(
          this.bankAccount.length - digits,
          this.bankAccount.length
        )
      );
    }
  }
}
