
import { Component, Vue } from "vue-property-decorator";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import { mapGetters } from "vuex";
import { IApiResponse } from "@/models/Global";

@Component({
  components: { ProfilePictureWidget, HiboLoader },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
})
export default class ProfilePersonalisation extends Vue {
  protected profileImageModal = false;
  protected loading = false;
  protected status = "start";
  protected errorMsg = "";

  async handleFile(e: any) {
    const fileUpload: any = e.target.files[0];
    this.loading = true;
    this.profileImageModal = true;
    const result: IApiResponse = await this.$store.dispatch(
      "auth/profileUpdateAvatar",
      { file: fileUpload }
    );
    if (result.resTextKey) this.errorMsg = this.$t(result.resTextKey) as string;
    this.status = result.success ? "success" : "error";
    this.loading = false;
  }

  closeModal() {
    this.profileImageModal = false;
    this.status = "start";
  }
}
